/**
 * Created by tx on 2021-02-25.
 */
import axios from '@/utils/wxAxios.js'

//获取微信小程序配置信息
// export function getWxFun(pageUrl) {
//     return axios.get('/api/Test/GetWxFun?type=2&content=' + pageUrl);
// }

//获取用户授权信息
export function getUserInfo(openId) {
    return axios.get('/api/XCXJ/GetUserInfo?openId=' + openId);
}
//退费
export function AliRefund(tradeNum, refudeFee) {
    return axios.get(`/api/AliPay/AliRefund?tradeNum=${tradeNum}&refudeFee=${refudeFee}`);
}