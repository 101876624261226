/**
 * Created by zkx on 2019/7/5.
 */
export default {
    getUrlParam: (url) => {
        const search = url.split('#')[0].split('?')[1]
        if (!search) {
            return {}
        }
        return JSON.parse(
            '{"' +
            decodeURIComponent(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"')
                .replace(/\+/g, ' ') +
            '"}'
        )
    }
}