<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
    import store from '@/store';

    function getQueryVariable(variable) {
      var href = window.location.search;
      var query = href.substring(href.indexOf('?') + 1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return (false);
    }

    export default {
        name: 'APP',
        metaInfo: {
            title: '云医院',
            meta: [
                { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' }
            ]
        },
        mounted(){
          let auth = JSON.parse(decodeURI(getQueryVariable('auth')) == 'undefined' ? '{}' : decodeURI(getQueryVariable('auth')));
          // console.log(auth);
          store.dispatch('user/setLogInfo', {
              phoneNumber: auth.mobile,
              userId: auth.userid,
              NickName: auth.username,
              Certno: auth.certno,
            });
        }
    }
</script>

<style>
  .van-button{
    border-radius: 8px;
  }
  .van-badge{
    border: none;
  }
  .van-uploader__input-wrapper{
    width: 100%;
  }
</style>
