import axios from '@/utils/axios.js'
import moment from 'moment'

/********** 用户登录 **********/
//发送短信验证码
export function getCheckNumber (phoneNumber) {
    return axios.get('/api/User/SendMsgCode?phone=' + phoneNumber + '&sendTime=' + moment().format('YYYY-MM-DD HH:mm:ss'))
}
//验证验证码
export function checkPhoneNumber (phoneNumber, checkNumber) {
    return axios.get('/api/User/CheckMsgCode?phone=' + phoneNumber + '&code=' + checkNumber)
}
//云医院通过手机号登陆
export function userLogIn (phoneNumber, appId) {
    return axios.get('/api/User/LoginByPhoneNum?phone=' + phoneNumber + '&appId=' + appId + '&type=1')
}
//获取软件广告
export function appBanner () {
    return axios.get('/api/User/AppBanner?type=广告')
}

/********** 用户地址 **********/
//获取用户收货地址
export function getUserAddress (userId) {
    return axios.get('/api/OutPatient/GetAddress?userid=' + userId)
}
//获取镇街列表
export function getAddressStreet (areacode) {
    return axios.get('/api/OutPatient/GetAddressStreet?areacode=' + areacode)
}
//添加用户收货地址
export function insertUserAddress (postData) {
    return axios.post('/api/OutPatient/AddAddress', postData)
}
//获取地址详情
export function getAddressInfo (addressId) {
    return axios.get('/api/OutPatient/GetAddressByID?id=' + addressId)
}
//修改用户收货地址
export function updateUserAddress (postData) {
    return axios.post('/api/OutPatient/ModAddress', postData)
}
//删除用户收货地址
export function deleteUserAddress (addressId) {
    return axios.get('/api/OutPatient/DelAddress?id=' + addressId)
}
//设置默认收货地址
export function setDefaultAddress (userId, addressId) {
    return axios.get('/api/OutPatient/SetDefaultAddress?userid=' + userId + '&id=' + addressId)
}

/********** 药品物流 **********/
//获取云医院药品列表    CardID：磁卡号码
export function GetDrugLogistics (CardID, index, size) {
    return axios.get('/api/DrugLogistic/GetDrugLogistics?cardID=' + CardID + '&index=' + index + '&size=' + size)
}
//获取云门诊药品物流详情
export function GetDrug (jzxh) {
    return axios.get('/api/DrugLogistic/GetDrug?jzxh=' + jzxh)
}

/********** 个人账户 **********/
//上传文件
export function UploadFileBase64 (files, type, fileName, cardId) {//type=1:身份证(cardID可传空)    2：病历
    let postData = []
    if (Array.isArray(files)) {
        for (let file of files) {
            let str1 = file.file.name.substring(0, file.file.name.indexOf("?"))
            let str2 = file.file.name.substring(str1.length + 1, file.file.name.length)
            postData.push({
                // name: file.file.name,
                name: fileName + str2,
                content: file.content,
                type: type.toString(),
                cardId: cardId
            })
        }
    } else {
        let str1 = files.file.name.substring(0, files.file.name.indexOf("?"))
        let str2 = files.file.name.substring(str1.length + 1, files.file.name.length)
        postData.push({
            // name: files.file.name,
            name: fileName + str2,
            content: files.content,
            type: type.toString(),
            cardId: cardId

        })
    }
    // console.log(JSON.stringify(postData))
    return axios.post('/api/User/UploadFileBase64', postData)
}
//读取身份证信息
export function GetCardInfo (filePath) {
    return axios.get('/api/User/GetCardInfo?filePath=' + filePath)
}
//读取身份证信息base64
export function GetCardInfoBase64 (postData) {
    return axios.post('/api/User/GetCardInfoBase64', postData)
}
//读取身份证背面信息base64
export function GetCardInfoBackBase64 (postData) {
    return axios.post('/api/User/GetCardInfoBackBase64', postData)
}
//保存身份证信息
export function AddCardInfo (postData) {
    return axios.post('/api/User/AddCardInfo', postData)
}
//检索病人账户号信息
export function QueryAccount (Type, number, ID) {
    return axios.get('/api/User/QueryAccount?Type=' + Type + '&number=' + number + '&ID=' + ID)
}
//验证病人账户信息
export function CheckPatientInfo (postData) {
    return axios.post('/api/User/CheckPatientInfo', postData)
}
//新增病人账户信息
export function AddAccount (postData) {
    return axios.post('/api/User/AddAccount', postData)
}
//新增病人账户信息list
export function AddAccountList (postData) {
    return axios.post('/api/User/AddAccountList', postData)
}
//新增病人档案
export function AddPatientRecord (postData) {
    return axios.post('/api/User/AddPatientRecord', postData)
}
//根据磁卡号获取病人信息
export function GetPatientCard (CardId) {
    return axios.get('/api/User/GetPatientCard?Card=' + CardId)
}
//保存病历路径信息
export function UpdateBRBL (Id, CardId, FilePath) {
    return axios.get('/api/User/UpdateBRBL?Id=' + Id + '&CardId=' + CardId + '&FilePath=' + FilePath)
}
//设为默认账户
export function SetDefaultAccount (CardId, Id) {
    return axios.get('/api/User/SetDefaultAccount?CardId=' + CardId + '&Id=' + Id)
}
//账户解绑
export function AccountDebind (CardId, Id) {
    return axios.get('/api/User/AccountDebind?CardId=' + CardId + '&Id=' + Id)
}
//云医院小程序账号磁卡信息批量解绑
export function DeleteByMZKH (postData) {
    return axios.post('/api/User/DeleteByMZKH', postData)
}
//云医院小程序手机号退出授权登录
export function DeletePhoneById (openId) {
    return axios.get('/api/User/DeletePhoneById?openId=' + openId)
}
//充值
export function Pay (postData) {
    return axios.post('/api/User/Pay', postData)
}
//退款
export function Refund (postData) {
    return axios.post('/api/User/Refund', postData)
}
//获取当前默认账户
export function getDefaultAccount (userId) {
    return axios.get('/api/User/GetDefaultAccount?Id=' + userId)
}
//费用结算
export function OutpatientCharge (postData) {
    return axios.post('/api/User/OutpatientCharge', postData)
}
//获取当前处方信息
export function GetOutPatientRecipe (jzxh) {
    return axios.get('/api/OutPatient/GetOutPatientRecipe?jzxh=' + jzxh)
}
//发送结算消息
export function SendSettlementMsg2User (postData) {
    return axios.post('/api/User/SendSettlementMsg2User', postData)
}

//type为1  产生电子发票
export function ElectronicInvoice (data) {
    return axios.post('/api/User/ElectronicInvoice', data)
}

// 查询/新增/结束 就诊
export function OrderOperation (ddbh, status) {
    return axios.get(`/api/User/OrderOperation?ddbh=${ddbh}&status=${status}`)
}
