import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
var SaveSocket='';
const User = {
    namespaced: true,
    state: {
        openId: '',
        userInfo: {
            AvatarUrl: "",
            City: "",
            Country: "",
            CreatTime: "",
            Gender: "",
            Id: 0,
            UserId: undefined,
            NickName: "用户",
            OpenId: "",
            PhoneNumber: "",
            Province: "",
            Certno: "",//身份证号码
        },
        medicalAssistant: false,
    },
    mutations: {
        SET_USER_INFO: (state, userInfo) => {
            userInfo.NickName = userInfo.NickName || '用户';
            state.userInfo = userInfo;
			localStorage.setItem('userPhoneNumber', state.userInfo.PhoneNumber);
        },
        SET_LOG_INFO: (state, logInfo) => {
            state.userInfo.UserId = logInfo.userId;
            state.userInfo.PhoneNumber = logInfo.phoneNumber;
            state.userInfo.NickName = logInfo.NickName || '用户';
            state.userInfo.Certno = logInfo.Certno || '';
			localStorage.setItem('userPhoneNumber', state.userInfo.PhoneNumber);
        },
        CLEAR_LOG_INFO: (state) => {
            state.userInfo = {
                AvatarUrl: "",
                City: "",
                Country: "",
                CreatTime: "",
                Gender: "",
                Id: 0,
                UserId: undefined,
                NickName: "用户",
                OpenId: "",
                PhoneNumber: "",
                Province: "",
                Certno: "",//身份证号码
            }
			localStorage.removeItem('userPhoneNumber');
        },
        SET_OPENID: (state, openId) => {
            state.openId = openId;
        },
        SET_MEDICAL: (state, medical) => {
            state.medicalAssistant = medical;
        }
    },
    actions: {
        setUserInfo({ commit }, userInfo){
            commit('SET_USER_INFO', userInfo);
        },
        setLogInfo({ commit }, logInfo){
            commit('SET_LOG_INFO', logInfo);
        },
        clearLogInfo({ commit }){
            commit('CLEAR_LOG_INFO');
        },
        setOpenId({ commit }, openId){
            commit('SET_OPENID', openId);
        },
        setMedicalAssistant({ commit }, medicalAssistant){
            commit('SET_MEDICAL', medicalAssistant);
        }
    }
}

const PageData = {
    namespaced: true,
    state: {
        activeTab: 'home',
        templateData: undefined,
        cardInfo: undefined
    },
    mutations: {
        SET_ACTIVE_TAB: (state, activeTab) => {
            state.activeTab = activeTab;
        },
        SET_DATA: (state, data) => {
            state.templateData = data;
        },
        CLEAR_DATA: (state) => {
            state.templateData = undefined;
        },
        SET_INFO: (state, data) => {
            state.cardInfo = data;
        },
        CLEAR_INFO: (state) => {
            state.cardInfo = undefined;
        }
    },
    actions: {
        setActiveTab({ commit }, activeTab){
            commit('SET_ACTIVE_TAB', activeTab);
        },
        setData({ commit }, data){
            commit('SET_DATA', data);
        },
        clearData({ commit }){
            commit('CLEAR_DATA');
        },
        setInfo({ commit }, data){
            commit('SET_INFO', data);
        },
        clearInfo({ commit }){
            commit('CLEAR_INFO');
        }
    }
}

export default new Vuex.Store({
    modules: {
        user: User,
        pageData: PageData,
        SaveSocket:SaveSocket,
    },
    getters: {
        openId: state => state.user.openId,
        userInfo: state => state.user.userInfo,
        medicalAssistant: state => state.user.medicalAssistant,
        activeTab: state => state.pageData.activeTab,
        temp: state => state.pageData.templateData,
        cardInfo: state => state.pageData.cardInfo,
    }
})

